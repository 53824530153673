<template>
  <div> 
    <dialog-loading v-if="saving"></dialog-loading>
    <v-row class="px-1">
      <v-col 
        cols="12" 
        :sm="$route.name == 'FormShow' ? 12 : 10" 
        :md="$route.name == 'FormShow' ? 12 : 8"  
        :lg="$route.name == 'FormShow' ? 12 : 6" 
        class="ml-auto mr-auto px-1"
      >
        <v-card v-if="course != null" >
          <v-card-title class="justify-center">
            <div :class="$vuetify.breakpoint.smAndUp ? 'text-h4' : 'text-h6'" style="word-break: break-word">{{course.name}}</div>
          </v-card-title>
          <v-card-text>
            <course-details :selected_course="course" no_course_name></course-details>
          </v-card-text>
        </v-card>
        <div class="pt-5">
          <form-content :course="course" @sendForm="sendForm"></form-content>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'
import validations from '@/mixins/validations';

export default {
  name: 'ShowForm',
  mixins: [
    validations
  ],
  components: {
    CourseDetails: () => import('../../../components/courses/CourseDetails.vue'),
    FormContent: () => import('./FormContent.vue'),
  },
  props: {
    is_manual_enrollment: {
      type: Boolean,
      default: false
    },
    course: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    valid: false,
    loading: false,
    saving: false,
    count: 0,
    form: {},
    read_only: false,
  }),
  mounted() {
  },
  methods: {
    async sendForm(form_id){
      this.saving = true
      let canSubmit = await this.canSubmitForm()
      if(canSubmit.is_valid_enroll){
        // this.courseValidate(true)
        let done_form = this.prepareDoneForm(form_id)
        this.createDoneForm(done_form)
      }else{
        this.saving = false
        this.$router.push({path: '/courses_calendar'})
        this.addNotification({type: 'error', msg: canSubmit.error_msg})
      }
    },
    async canSubmitForm(){
      if(this.$route.name == 'CourseRating'){
        return {is_valid_enroll: true, error_msg: ''}
      }
      if(this.is_manual_enrollment){
        return {is_valid_enroll: true, error_msg: ''}
      }
      return await Api.EnrollCourseValidate.index({course_id: this.course.id}).then(r => {
        return r.data
      }).catch(error => {
        console.log(error)
      })
    },
    prepareDoneForm(form_id){
      let done_form = {
        form_id: form_id,
        course_id: this.course.id,
        exec_time: this.$route.name == 'CourseRating' ? 'rating' : 'enrollment',
        user_id: this.user.role.name == 'student'? this.user.id : '',
      }
      let done_personal_info_attributes = this.user.role.name == "student" ? this.setDonePersonalInfo() : this.done_personal_info_attributes
      let done_items_attributes = [
          ...this.done_items_attributes,
      ]
      const formData = new FormData()
      Object.entries(done_form).forEach(([key, value]) => {
        if(value){
          formData.append('done_form['+key+']', value || '');
        }
      });
      Object.entries(done_personal_info_attributes).forEach(([key, value]) => {
          if(value){
            formData.append('done_form[done_personal_info_attributes]['+key+']', value || '');  
          }
      });
      for (let i=0; i< done_items_attributes.length; i++) {
        Object.entries(done_items_attributes[i]).forEach(([key, value]) => {
          if(value){
            formData.append(`done_form[done_items_attributes][${i}][`+key+`]`, value || '');  
          }
        });
      }
      return formData
    },
    createDoneForm(done_form){
      this.saving = true
      Api.DoneForm.createWithFormData(done_form).then( () => {
        this.addNotification({type: 'success', msg: 'Formulário enviado com sucesso!'})
        if(this.$route.name == 'CourseRating'){
          this.$router.push({name: 'CourseList'})
        } else if (this.$route.name == 'NewManualEnrollment'){
          this.$router.push({name: 'Select', params: {id: this.course.id}})
        } else{
          this.$router.push({name: 'CourseList'})
        }
        this.saving = false 
      }).catch((err) => {
        this.addNotification({type: 'error', msg: `ERRO ${err.response.status} - Não foi possível realizar o envio.`})
      }).finally(() => { 
        this.saving = false 
      })
    },
    setDonePersonalInfo(){
      return {
        name: this.user.name,
        country: this.user.country,
        state: this.user.state,
        city: this.user.city,
        email: this.user.email,
        birthdate: this.user.birthdate,
        phone: this.user.phone,
        curriculum_link: this.user.curriculum_link,
        curriculum_file: null,
      }
    },
    ...mapActions({
      addNotification: 'Notification/add',
      clearDoneForm: 'DoneForm/clearDoneForm'
    })
  },
  computed: {
    ...mapState({
      user: state=> state.User.user,
      done_personal_info_attributes: state=> state.DoneForm.done_personal_info_attributes,
      done_items_attributes: state=> state.DoneForm.done_items_attributes,
    }),
  },
  beforeDestroy() {
    this.clearDoneForm()
  },
}
</script>

<style scoped>

</style>